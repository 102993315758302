<template>
  <b-card>
    <b-row class="my-3" v-if="!loading.form">
      <b-col cols="12" md="12">
        <h4 class="mb-2 text-center">
         <span class="text-primary">{{ customer_name }}</span>
        </h4>
      </b-col>
    </b-row>
    <template v-if="loading.form">
      <b-row class="justify-content-md-center mt-2">
        <b-spinner big variant="primary" />
      </b-row>
    </template>
    <template v-else>
      <b-row>
        <b-col cols="12" md="6" class="mt-1" >
          Onde e com quem você reside atualmente?
        </b-col>
        <b-col cols="12" md="6" class="mt-1">
          <b-form-group >
            <b-form-textarea
              v-model="currentResidence"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" class="mt-1" >
          O que despertou seu interesse em empreender com a Futuro?
        </b-col>
        <b-col cols="12" md="6" class="mt-1">
          <b-form-group >
            <b-form-textarea
              v-model="interestFuturo"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" class="my-1" >
          <div class="orientation-space">
            Validação de perfil
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" class="mt-1" >
          Em seu tempo livre, você prefere ficar em casa ou sair com amigos? Por que faz essa escolha?
        </b-col>
        <b-col cols="12" md="6" class="mt-1">
          <b-form-group >
            <b-form-textarea
              v-model="leisurePreference"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" class="mt-1" >
          Quais são as cinco pessoas mais próximas de você? Como as conheceu? Qual a profissão de seus pais?
        </b-col>
        <b-col cols="12" md="6" class="mt-1">
          <b-form-group >
            <b-form-textarea
              v-model="closeNetwork"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" class="mt-1" >
          Conte um pouco sobre sua trajetória profissional. Relate um desafio marcante na sua carreira e quais ações tomou para superá-lo.
        </b-col>
        <b-col cols="12" md="6" class="mt-1">
          <b-form-group >
            <b-form-textarea
              v-model="careerPath"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" class="mt-1" >
          Liderar quando se é o líder formal é fácil. Conte um exemplo que você teve que liderar sem ser o líder oficial do grupo.
        </b-col>
        <b-col cols="12" md="6" class="mt-1">
          <b-form-group >
            <b-form-textarea
              v-model="careerChallenge"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" class="mt-1" >
          Qual é sua renda atual? E seu custo de vida mensal? Caso não receba pró-labore, 
          como se sustenta hoje? Qual a sua visão sobre receber pró-labore apenas se houver faturamento, 
          considerando que quanto maior o faturamento, maior será o pró-labore?
        </b-col>
        <b-col cols="12" md="6" class="mt-1">
          <b-form-group >
            <b-form-textarea
              v-model="income"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" class="mt-1" >
          Como você se enxerga como empreendedor(a)? Prefere algo mais enxuto, como os irmãos McDonald, 
          que ficaram satisfeitos com uma única franquia, ou tem o perfil de Ray Kroc, que expandiu para milhares de unidades no mundo?
        </b-col>
        <b-col cols="12" md="6" class="mt-1">
          <b-form-group >
            <b-form-textarea
              v-model="informalLeadership"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" class="mt-1" >
          Por que você deseja empreender com a Futuro? Qual empresário(a) você admira e por quê?
        </b-col>
        <b-col cols="12" md="6" class="mt-1">
          <b-form-group >
            <b-form-textarea
              v-model="entrepeneurAdmiration"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" class="my-1" >
          <div class="orientation-space">
            Alinhamentos de expectativas
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" class="mt-1" >
          Quantas horas por semana você pode dedicar às atividades relacionadas à Futuro?
        </b-col>
        <b-col cols="12" md="6" class="mt-1">
          <b-form-group >
            <b-form-textarea
              v-model="weeklyAvailability"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" class="mt-1" >
          Considerando nossa projeção de resultados, a formação oferecida e o acompanhamento de um(a) mentor(a), 
          você acredita ser capaz de entregar 5 EA (em regime parcial) ou 10 EA (em regime integral) por semana? 
          Se não for possível, qual seria sua meta realista?
        </b-col>
        <b-col cols="12" md="6" class="mt-1">
          <b-form-group >
            <b-form-textarea
              v-model="sustenanceProLabore"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" class="mt-1" >
          Em geral, as pessoas são contratadas pelo seu potencial e habilidades, 
          mas desligadas devido a comportamento. Pontualidade, respeito e comunicação eficiente são cruciais. 
          Mesmo que você seja um(a) excelente Parceiro(a) ou Franqueado(a) em resultados, 
          se seu comportamento estiver desalinhado com nossos valores, poderemos encerrar a parceria. 
          Você concorda com esse posicionamento?
        </b-col>
        <b-col cols="12" md="6" class="mt-1">
          <b-form-group >
            <b-form-textarea
              v-model="valuesAlignment"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" class="mt-1" >
          Estou buscando um(a) candidato(a) com competências fundamentais para empreender ao nosso lado. 
          Existem outros(as) com perfis semelhantes. Dê-me três motivos pelos quais devo escolher você em vez dos demais.
        </b-col>
        <b-col cols="12" md="6" class="mt-1">
          <b-form-group >
            <b-form-textarea
              v-model="candidateDifferentials"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" class="mt-1" >
          Alinhar uma data para o retorno. Fica combinado que o candidato entrará em contato na data estabelecida. 
          Podemos definir um horário específico ou um intervalo de tempo. Tudo bem para você?
        </b-col>
        <b-col cols="12" md="6" class="mt-1">
          <b-form-group >
            <b-form-textarea
              v-model="followUpDate"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-for="data in otherQuestions" :key="data.key">
        <b-col cols="12" md="6" class="mt-1" >
          <b-form-textarea
            v-model="data.question"
          />
        </b-col>
        <b-col cols="12" md="6" class="mt-1">
          <b-form-group >
            <b-form-textarea
              v-model="data.answer"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex mt-1 justify-content-start">
        <b-button
          :disabled="saving"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-success"
          @click="addQuestion"
        >
          Adicionar pergunta
        </b-button>
      </div>
      <div class="d-flex mt-1 justify-content-end">
        <b-button
          :disabled="saving"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          @click="goToLead"
          class="lead-buttons"
        >
          Cancelar
        </b-button>
        <b-button
          :disabled="saving"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="lead-buttons ml-2"
          @click="onSubmit"
        >
          {{ saving ? "Salvando..." : "Salvar" }}
        </b-button>
      </div>
    </template>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BCol,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";
import { minValue, maxValue } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import vSelect from "vue-select";
import { VMoney } from "v-money";
import { getVueSelectErrorClass } from "@/helpers/validators";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import { dataTool } from "echarts/lib/echarts";
import { title } from "echarts/lib/theme/dark";
import { clear } from "echarts/lib/util/throttle";
import _ from "lodash";

export default {
  components: {
    BButton,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCol,
    BRow,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
    money: VMoney,
  },
  data() {
    return {
      customer_name: '',
      saving: false,
      loading: {
        form: false,
      },
      currentResidence: undefined,
      interestFuturo: undefined,
      leisurePreference: undefined,
      closeNetwork: undefined,
      entrepeneurAdmiration: undefined,
      careerPath: undefined,
      careerChallenge: undefined,
      informalLeadership: undefined,
      income: undefined,
      weeklyAvailability: undefined,
      sustenanceProLabore: undefined,
      valuesAlignment: undefined,
      candidateDifferentials: undefined,
      followUpDate: undefined,
      otherQuestions: []
    };
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations() {
    return {
    };
  },
  computed: {

  },
  mounted() {
    this.loading.form = true;
    this.getReport(this.$route.params.id)
      .then((response) => {
        this.customer_name = response.data.customer_name;
        this.currentResidence = response.data.report.current_residence;
        this.interestFuturo = response.data.report.interest_futuro;
        this.leisurePreference = response.data.report.leisure_preference;
        this.closeNetwork = response.data.report.close_network;
        this.entrepeneurAdmiration = response.data.report.entrepeneur_admiration;
        this.careerPath = response.data.report.career_path;
        this.careerChallenge = response.data.report.career_challenge;
        this.informalLeadership = response.data.report.informal_leadership;
        this.income = response.data.report.income;
        this.weeklyAvailability = response.data.report.weekly_availability;
        this.sustenanceProLabore = response.data.report.sustenance_pro_labore;
        this.valuesAlignment = response.data.report.values_alignment;
        this.candidateDifferentials = response.data.report.candidate_differentials;
        this.followUpDate = response.data.report.follow_up_date;
        this.otherQuestions = response.data.report.other_questions? 
          JSON.parse(response.data.report.other_questions) : [];
        this.loading.form = false;
      })
      .catch((error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar o candidato. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        setTimeout(() => {
          this.$router.back();
          this.loading.form = false;
        }, 2000);
      });
  },
  methods: {
    ...mapActions({
      getReport: types.GET_INDIVIDUAL_INTERVIEW_REPORT,
      saveIndividualInterviewReport: types.SAVE_INDIVIDUAL_INTERVIEW_REPORT,
    }),
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },
    addQuestion() {
      this.otherQuestions.push({
        question: undefined,
        answer: undefined,
        key: this.otherQuestions.length + 1
      });
    },
    onSubmit(){
      this.saving = true;
      const {
        currentResidence,
        interestFuturo,
        leisurePreference,
        closeNetwork,
        entrepeneurAdmiration,
        careerPath,
        careerChallenge,
        informalLeadership,
        income,
        weeklyAvailability,
        sustenanceProLabore,
        valuesAlignment,
        candidateDifferentials,
        followUpDate,       
        otherQuestions
      } = this
      
      const questions = _.filter(otherQuestions, (item) => {
        return item.question || item.answer;
      });

      this.saveIndividualInterviewReport({
        customer_id: this.$route.params.id,
        current_residence: currentResidence,
        interest_futuro: interestFuturo,
        leisure_preference: leisurePreference,
        close_network: closeNetwork,
        entrepeneur_admiration: entrepeneurAdmiration,
        career_path: careerPath,
        career_challenge: careerChallenge,
        informal_leadership: informalLeadership,
        income,
        weekly_availability: weeklyAvailability,
        sustenance_pro_labore: sustenanceProLabore,
        values_alignment: valuesAlignment,
        candidate_differentials: candidateDifferentials,
        follow_up_date: followUpDate,
        other_questions: JSON.stringify(questions),
      })
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso!",
              text: "Questionário da entrevista salvo com sucesso.",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.goToLead()
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao salvar o relatório da entrevista. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    goToLead(){
      this.$router.push({
        name: "lead-pn-profile",
        params: { id: this.$route.params.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.orientation-space {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #dcdcdc;
  height: 40px;
  border-radius: 10px;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
}
.dark-layout .orientation-space{
  background-color: #1e263a;
}

textarea{
  min-height: 80px !important;
}
</style>
